export const negativeWords = [
    'a',
    'all',
    'an',
    'and',
    'another',
    'any',
    'are',
    'as',
    'as it',
    'at',
    'at',
    'be',
    'but',
    'can',
    'copyright',
    'do',
    'don’t',
    'for',
    'from',
    'has',
    'have',
    'he',
    'her',
    'him',
    'his',
    'i',
    'if',
    'in',
    'is',
    'it',
    'its',
    'just',
    'let',
    'like',
    'mine',
    'mm',
    'my',
    'need',
    'newsletter',
    'no',
    'not',
    'of',
    'on',
    'only',
    'or',
    'our',
    'rsquo',
    'she',
    'should',
    'so',
    'that',
    'the',
    'their',
    'them',
    'they',
    'this',
    'to',
    'uno',
    'via',
    'want',
    'was',
    'we',
    'were',
    'were',
    'what',
    'which',
    'why',
    'will',
    'with',
    'you',
    'your'
];