import React, { useEffect, useRef, useState } from 'react'
import ThesaurusDialog from '../thesaurusDialog/ThesaurusDialog';

const WordComponent = ({ wordDetails, onWordSelected }) => {
    const [className, setClassName] = useState('');
    const [thesaurusVisible, setThesaurusVisible] = useState(false);
    const wordRef = useRef(null);

    useEffect(() => {
        if (wordDetails.thesaurus) {
            setClassName('thesaurus');
        }
    }, []);

    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutside);

        return () => {
            window.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wordRef]);

    const hideThesaurus = () => {
        setThesaurusVisible(false);
    }

    const handleOnClick = () => {
        if (wordDetails.thesaurus) {
            setThesaurusVisible(true);
        }
    }

    const handleClickOutside = (e) => {
        if (wordRef.current && !wordRef.current.contains(e.target)) {
            hideThesaurus();
        }
    };

    const handleWordSelected = (word) => {
        onWordSelected(word);
        hideThesaurus();
    }

    return (
        <div ref={wordRef} className="word-wrapper">
            <div className={`word ${className}`} dangerouslySetInnerHTML={{ __html: '<span>' + wordDetails.originalWord + '</span> ' }} onClick={handleOnClick}>
            </div>

            {thesaurusVisible && (
                <ThesaurusDialog word={wordDetails.word} onWordSelected={handleWordSelected} />
            )}
        </div>
    )
}

export default WordComponent;
