import { Fragment, useEffect, useState } from 'react';
import RewriteElement from '../rewriteElement/RewriteElement';
import './RewriteElementsGroup.css';

const RewriteElementsGroup = ({ items, tagGroup, showOriginal, showRewrite, calculateWordsCount, wordsCount }) => {
    const [groupType, setGroupType] = useState('');

    useEffect(() => {
        if (tagGroup.tagName === 'p') {
            setGroupType('paragraph');
        } else if (tagGroup.tagName === 'ul' || tagGroup.tagName === 'ol') {
            setGroupType('other');
        } else if (tagGroup.tagName.indexOf('h') > -1) {
            setGroupType('heading');
        }
    }, [tagGroup.tagName]);

    return (
        <div className={`elements-group ${groupType}`}>
            {items && (
                <Fragment>
                    {items.map((item) => (
                        <RewriteElement key={item.guid} rewriteNode={item} showOriginal={showOriginal} showRewrite={showRewrite} calculateWordsCount={calculateWordsCount} wordsCount={wordsCount} />
                    ))}
                </Fragment>
            )}
        </div>
    )
}

export default RewriteElementsGroup;
