import { Editor } from '@tinymce/tinymce-react';
import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { actionTypes } from '../../reducer';
import { v4 as uuidv4 } from 'uuid';
import { GetJson } from '../../Services';
import { useStateValue } from '../../StateProvider';
import Header from '../header/Header';
import './ContentComplete.css';

const ContentComplete = () => {
    const targetWordsCount = 1750;
    const history = useHistory();
    const [{ editorJson, wordsLimit }, dispatch] = useStateValue();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [editor, setEditor] = useState(null);
    const [content, setContent] = useState('');
    const [wordsCount, setWordsCount] = useState(0);
    const { rewriteId } = useParams();

    useEffect(() => {
        if (editorJson && editorJson.source.tagGroups && editorJson.source.tagGroups.length > 0) {
            let finishedContent = '';
            editorJson.source.tagGroups.map((tagGroup) => {
                if (tagGroup.items && tagGroup.items.length > 0) {
                    if (tagGroup.tagName === 'p') {
                        finishedContent += '<p>';
                    }

                    tagGroup.items.map((item) => {
                        console.log('item html: ' + item.rewrite.html);

                        if (item.rewrite.tagName !== 'p' && tagGroup.tagName === 'p' && item.rewrite.html.trim() !== '' && item.rewrite.html.trim() !== '<br>') {
                            finishedContent += '</p>';
                        }

                        let previousItem = tagGroup.items[tagGroup.items.indexOf(item) - 1];

                        if (previousItem && previousItem.tagName !== item.tagName && item.tagName === 'p') {
                            finishedContent += '<p>';
                        }

                        if (item.rewrite.html.trim() !== '' && item.rewrite.html.trim() != '<br>' && item.rewrite.html.trim() != '<br/>' && item.rewrite.html.trim() != '<br />') {
                            if (item.rewrite.tagName === 'p') {
                                finishedContent += `${item.rewrite.html.replace('<p>', '').replace('</p>', '')} `;
                            } else {
                                finishedContent += `${item.rewrite.html}`;
                            }
                            //finishedContent += `<${item.rewrite.tagName}>${item.rewrite.html}</${item.rewrite.tagName}>`;
                        }

                        if (item == tagGroup.items[tagGroup.items.length - 1] && finishedContent.endsWith(' ')) {
                            finishedContent = finishedContent.substr(0, finishedContent.length - 1);
                        }
                    });

                    if (tagGroup.tagName === 'p' && !finishedContent.endsWith('</p>')) {
                        finishedContent += '</p>';
                    }
                }
            });

            while (finishedContent.indexOf('<p></p>') > -1) {
                finishedContent = finishedContent.replace('<p></p>', '');
            }

            setContent(finishedContent);
            console.log('finished:', finishedContent);
        }
    }, [editorJson]);

    useEffect(() => {
        if (rewriteId) {
            loadJson();
        }
    }, [rewriteId]);

    const loadJson = async () => {
        let tempData = {};
        tempData.Guid = rewriteId;

        const response = await GetJson(tempData);

        if (response.IsSuccessful) {
            let tempJson = JSON.parse(response.Data);
            let tempArray = new Array();

            if (tempJson.WordsLimit) {
                let wordsLimit = parseInt(tempJson.WordsLimit);

                dispatch({
                    type: actionTypes.SET_WORDS_LIMIT,
                    wordsLimit: wordsLimit
                });
            }

            let tagCounter = 0;
            tempJson.source.tagGroups.map((tagGroup) => {
                tagGroup.guid = uuidv4();
                tagGroup.id = tagCounter;
                if (tagGroup.items && tagGroup.items.length > 0) {
                    let itemCounter = 0;
                    tagGroup.items.map((item) => {
                        item.id = itemCounter;
                        item.parentGuid = tagGroup.guid;
                        item.guid = uuidv4();

                        if (!item.rewrite) {
                            item.rewrite = {
                                tagName: item.tagName,
                                html: ''
                            };

                            if (item.html.indexOf('<ul>') > -1 || item.html.indexOf('<ol>') > -1) {
                                item.rewrite.html = item.html;
                            }
                        } else {
                            if (item.rewrite.html === '' && (item.html.indexOf('<ul>') > -1 || item.html.indexOf('<ol>') > -1)) {
                                item.rewrite.html = item.html;
                            }
                        }

                        itemCounter++;
                    });
                }

                tagCounter++;
            });

            dispatch({
                type: actionTypes.SET_EDITOR_JSON,
                editorJson: tempJson
            });

            setTimeout(() => {
                setDataLoaded(true);
            }, 100);
        } else {
            window.alert('Content not found!');
        }
    }

    useEffect(() => {
        if (editor && content.trim() !== '') {
            const wordcount = editor.plugins.wordcount;
            // let textContent = editor.getContent({ format: 'text' });

            // let words = textContent.split(' ');

            // setWordsCount(words.length);
            setWordsCount(wordcount.body.getWordCount());
        } else {
            setWordsCount(0);
        }
    }, [editor, content]);

    const handleEditorInit = (evt, editor) => {
        setEditor(editor);
    }

    const handleEditorChange = (newContent, editor) => {
        console.log('content changed');
        setContent(newContent);
    }

    const handleBackButton = () => {
        history.goBack();
    }

    return (
        <Fragment>
            <Header />

            <div className="container complete-container">
                <div className="top-stats-container">
                    <button type="button" className="btn btn-primary" onClick={handleBackButton}>Back</button>
                    <div className="content-stat">
                        <div>Words Count:</div>
                        <div className="green">{wordsCount}</div>
                    </div>
                    <div className="content-stat">
                        <div>Target Words Count:</div>
                        <div>{wordsLimit}</div>
                    </div>
                </div>
                <div className="content-instructions">
                    <ol>
                        <li>Please make sure all paragraphs are no more than 80 words.</li>
                        <li>Check to make sure content follows all of the specific client’s instructions.</li>
                        <li>Please select all of the visible content (ctrl + A) below and click Copy.</li>
                        <li>Paste content into the content editor where you were assigned this task on SteadyContent.com</li>
                    </ol>
                </div>

                <div className="rewrite-instructions content-complete">
                    <div className="rewrite-instructions-video">
                        <span className="wistia_embed wistia_async_0ilmh21rbu popover=true popoverContent=link">
                            <a href="#" className="link-training"><i class="fab fa-youtube"></i><span>Watch Training Video!</span></a>
                        </span>
                    </div>
                </div>
                {content && content !== '' && (
                    <Fragment>
                        <Editor
                            key="wi1lao33ed5u2op9p024nsxdc3yvuvb2qpmu5diz4jc1cxtu"
                            init={{
                                valid_elements: "p[style],strong,em,span[style],a[href],ul,ol,li,h1,h2,h3,h4,h5,h6",
                                menubar: false,
                                statusbar: false,
                                toolbar: "formatselect | italic underline | numlist bullist | blockquote link | code",
                                plugins: ['wordcount link lists code autolink'],
                                style_formats: [
                                    { title: 'Paragraph', inline: 'p' },
                                    { title: 'Heading', block: 'h2' }
                                ]
                            }}
                            inline={false}
                            initialValue={content}
                            onInit={handleEditorInit}
                            onEditorChange={handleEditorChange} />
                    </Fragment>
                )}

            </div>
        </Fragment>
    )
}

export default ContentComplete;
