import React, { useEffect, useState } from 'react';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import './TimerComponent.css';

const TimerComponent = () => {
    const [{ editorJson, writingTime }, dispatch] = useStateValue();
    const [timerEnabled, setTimerEnabled] = useState(false);
    const [currentSeconds, setCurrentSeconds] = useState(-1);
    const [displayTime, setDisplayTime] = useState('');
    const [timerInterval, setTimerInterval] = useState(null);

    useEffect(() => {
        return () => {
            clearInterval(timerInterval);
        }
    }, []);

    useEffect(() => {
        if (editorJson) {
            let time = 0;

            if (editorJson.totalTime) {
                time = editorJson.totalTime;
            }

            setCurrentSeconds(time);

            if (time === 0) {
                setTimerEnabled(true);
            }

            dispatch({
                type: actionTypes.SET_WRITING_TIME,
                writingTime: time
            });
        }
    }, [editorJson, editorJson.totalTime]);

    useEffect(() => {
        if (currentSeconds >= 0) {
            let tempDisplayTime = fancyTimeFormat(currentSeconds);

            setDisplayTime(tempDisplayTime);

            dispatch({
                type: actionTypes.SET_WRITING_TIME,
                writingTime: currentSeconds
            });
        }
    }, [currentSeconds]);

    useEffect(() => {
        if (timerEnabled) {
            setTimerInterval(setInterval(() => {
                timerTick();
            }, 1000));
        } else {
            clearInterval(timerInterval);
        }
    }, [timerEnabled]);

    const timerTick = () => {
        setCurrentSeconds(currentSeconds => currentSeconds + 1);
    }

    const fancyTimeFormat = (seconds) => {
        const format = val => `0${Math.floor(val)}`.slice(-2)
        const hours = seconds / 3600
        const minutes = (seconds % 3600) / 60

        return [hours, minutes, seconds % 60].map(format).join(':')
    }

    const handleStart = () => {
        setTimerEnabled(true);
    }

    const handleStop = () => {
        setTimerEnabled(false);
        clearInterval(timerInterval);
    }

    const handleReset = () => {
        setCurrentSeconds(0);
    }

    return (
        <div className="timer-container">
            {!timerEnabled ? (
                <button type="button" className="btn btn-primary" onClick={handleStart}>Start</button>
            ) : (
                    <button type="button" className="btn btn-primary" onClick={handleStop}>Stop</button>
                )}

            <button type="button" className="btn btn-primary" onClick={handleReset}>Reset</button>

            <span className="timer">
                {displayTime}
            </span>
        </div>
    )
}

export default TimerComponent;
