import { Editor } from '@tinymce/tinymce-react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { actionTypes } from '../../reducer';
import { SaveJson } from '../../Services';
import { useStateValue } from '../../StateProvider';
// import { negativeWords } from '../../negativeWords';
import './RewriteElement.css';
import WordComponent from '../wordComponent/WordComponent';

const RewriteElement = ({ rewriteNode, showOriginal, showRewrite, calculateWordsCount, wordsCount }) => {
    const [{ editorJson, wordsLimit, negativeTerms: negativeWords }, dispatch] = useStateValue();
    const [editor, setEditor] = useState(null);
    const [content, setContent] = useState('');
    const [tagGroup, setTagGroup] = useState(null);
    const [tagGroupIndex, setTagGroupIndex] = useState(-1);
    const [contentLength, setContentLength] = useState(0);
    const [elementType, setElementType] = useState('');
    const [headingType, setHeadingType] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [originalSentence, setOriginalSentence] = useState([]);
    const [newItemClass, setNewItemClass] = useState('');
    const elementDivRef = useRef(null);
    const match = useRouteMatch();

    useEffect(() => {
        if (rewriteNode.rewrite.html.trim() !== '') {
            setContent(rewriteNode.rewrite.html);
        } else {
            if (rewriteNode.html.indexOf('<h1>') > -1) {
                setContent('<h1></h1>');
            } else if (rewriteNode.html.indexOf('<h2>') > -1) {
                setContent('<h2></h2>');
            } else if (rewriteNode.html.indexOf('<h3>') > -1) {
                setContent('<h3></h3>');
            } else if (rewriteNode.html.indexOf('<h4>') > -1) {
                setContent('<h4></h4>');
            } else if (rewriteNode.html.indexOf('<h5>') > -1) {
                setContent('<h5></h5>');
            } else if (rewriteNode.html.indexOf('<h6>') > -1) {
                setContent('<h6></h6>');
            } else if (rewriteNode.html.indexOf('<ul>') > -1 || rewriteNode.html.indexOf('<ol>') > -1) {
                setContent(rewriteNode.html);
            }
        }

        if (rewriteNode.html.trim() !== '') {
            let doc = new DOMParser().parseFromString(rewriteNode.html.trim(), "text/html");

            let words = doc.all[0].innerText.split(' ');

            if (words && words.length > 0) {
                const lettersRegex = /[a-zA-Z]+/g;
                let sentenceWords = new Array();

                for (let i = 0; i < words.length; i++) {
                    let regexMatch = words[i].match(lettersRegex);
                    let sentenceWord = {};
                    sentenceWord.word = regexMatch ? regexMatch[0] : '';
                    sentenceWord.originalWord = words[i];
                    sentenceWord.thesaurus = false;

                    if (negativeWords.indexOf(sentenceWord.word.toLowerCase()) < 0) {
                        // word should use thesaurus, mark it as such
                        sentenceWord.thesaurus = true;
                    }

                    sentenceWords.push(sentenceWord);
                }

                setOriginalSentence(sentenceWords);
            }
        }

        setIsLoading(false);
    }, []);

    useEffect(() => {
        // runs every time newItem value changes
        if (rewriteNode.newItem && tagGroup) {
            setNewItemClass('new');

            let tempJson = editorJson;

            let tempTagGroup = tagGroup;
            tempTagGroup.items[tempTagGroup.items.indexOf(rewriteNode)].newItem = false;

            tempJson.source.tagGroups[tempTagGroup.id] = tempTagGroup;

            dispatch({
                type: actionTypes.SET_EDITOR_JSON,
                editorJson: tempJson
            });

            setTimeout(() => {
                setNewItemClass('');
            }, 2000);
        }
    }, [rewriteNode.newItem, tagGroup]);

    useEffect(() => {
        if (content !== '') {
            if (content.indexOf('<h1>') > -1 || content.indexOf('<h2>') > -1 || content.indexOf('<h3>') > -1 || content.indexOf('<h4>') > -1 || content.indexOf('<h5>') > -1 || content.indexOf('<h6>') > -1) {
                setElementType('heading');

                if (content.indexOf('<h1>') > -1) {
                    setHeadingType('h1');
                } else if (content.indexOf('<h2>') > -1) {
                    setHeadingType('h2');
                } else if (content.indexOf('<h3>') > -1) {
                    setHeadingType('h3');
                } else if (content.indexOf('<h4>') > -1) {
                    setHeadingType('h4');
                } else if (content.indexOf('<h5>') > -1) {
                    setHeadingType('h5');
                } else if (content.indexOf('<h6>') > -1) {
                    setHeadingType('h6');
                }
            } else if (content.indexOf('<p>') > -1) {
                setElementType('paragraph');
            } else {
                setElementType('other');
            }
        } else {
            if (content.indexOf('<h1>') > -1 || content.indexOf('<h2>') > -1 || content.indexOf('<h3>') > -1 || content.indexOf('<h4>') > -1 || content.indexOf('<h5>') > -1 || content.indexOf('<h6>') > -1) {
                setElementType('heading');

                if (content.indexOf('<h1>') > -1) {
                    setHeadingType('h1');
                } else if (content.indexOf('<h2>') > -1) {
                    setHeadingType('h2');
                } else if (content.indexOf('<h3>') > -1) {
                    setHeadingType('h3');
                } else if (content.indexOf('<h4>') > -1) {
                    setHeadingType('h4');
                } else if (content.indexOf('<h5>') > -1) {
                    setHeadingType('h5');
                } else if (content.indexOf('<h6>') > -1) {
                    setHeadingType('h6');
                }
            } else if (rewriteNode.html.indexOf('<p>') > -1) {
                setElementType('paragraph');
            } else {
                setElementType('other');
            }
        }

        if (editor) {
            //let currentContent = editor.getContent({ format: 'text' });
            //let words = currentContent.split(' ');
            //setContentLength(words.length);
            //calculateWordsCount();
        }
    }, [content, editor]);

    useEffect(() => {
        if (editorJson) {
            let tempTagGroup = editorJson.source.tagGroups.find(x => x.guid === rewriteNode.parentGuid);
            let tempTagGroupIndex = editorJson.source.tagGroups.indexOf(tempTagGroup);

            setTagGroup(tempTagGroup);
            setTagGroupIndex(tempTagGroupIndex);

            // console.log('tag group index: ', tempTagGroupIndex);
        }
    }, [rewriteNode]);

    const handleEditorInit = (evt, editor) => {
        setEditor(editor);
    }

    const handleEditorChange = (newContent, editor) => {
        setContent(newContent);

        if (newContent.indexOf('<h1>') > -1 || newContent.indexOf('<h2>') > -1 || newContent.indexOf('<h3>') > -1 || newContent.indexOf('<h4>') > -1 || newContent.indexOf('<h5>') > -1 || newContent.indexOf('<h6>') > -1) {
            setElementType('heading');

            if (newContent.indexOf('<h1>') > -1) {
                setHeadingType('h1');
            } else if (newContent.indexOf('<h2>') > -1) {
                setHeadingType('h2');
            } else if (newContent.indexOf('<h3>') > -1) {
                setHeadingType('h3');
            } else if (newContent.indexOf('<h4>') > -1) {
                setHeadingType('h4');
            } else if (newContent.indexOf('<h5>') > -1) {
                setHeadingType('h5');
            } else if (newContent.indexOf('<h6>') > -1) {
                setHeadingType('h6');
            }
        } else if (newContent.indexOf('<p>') > -1) {
            setElementType('paragraph');
            setHeadingType('');
        } else {
            setElementType('other');
            setHeadingType('');
        }
    }

    const handleEditorOnFocus = (evt, editor) => {
        const yOffset = -200;
        const y = elementDivRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
        // elementDivRef.current.scrollIntoView();
    }

    const handleEditorOnBlur = (evt, editor) => {
        let currentHtml = editor.getContent();
        let currentContent = editor.getContent({ format: 'text' });

        currentContent = currentHtml;

        // while (currentContent.indexOf('&nbsp;</') > -1 || currentContent.indexOf(' </') > -1) {
        //     currentContent = currentContent.replace('&nbsp;</', '</').replace(' </', '</');
        // }

        // if (currentContent.indexOf('&nbsp;</') > -1) {
        //     currentContent = currentContent.replace('&nbsp;</', '</');
        // }

        if (currentContent !== currentHtml) {
            setContent(currentContent);
        }

        let tempJson = editorJson;

        let tempTagGroup = tagGroup;
        tempTagGroup.items[tempTagGroup.items.indexOf(rewriteNode)].rewrite.html = currentContent;

        tempJson.source.tagGroups[tempTagGroup.id] = tempTagGroup;

        dispatch({
            type: actionTypes.SET_EDITOR_JSON,
            editorJson: tempJson
        });

        calculateWordsCount();
    }

    const handleChangeToHeadline = () => {
        let currentContent = editor.getContent({ format: 'text' });

        setContent('<h2>' + currentContent + '</h2>');
        setHeadingType('h2');

        let tempJson = editorJson;

        let tempTagGroup = tagGroup;
        tempTagGroup.items[tempTagGroup.items.indexOf(rewriteNode)].rewrite.html = '<h2>' + currentContent + '</h2>';
        tempTagGroup.items[tempTagGroup.items.indexOf(rewriteNode)].rewrite.tagName = 'h2';

        tempJson.source.tagGroups[tempTagGroup.id] = tempTagGroup;

        dispatch({
            type: actionTypes.SET_EDITOR_JSON,
            editorJson: tempJson
        });
    }

    const handleChangeToParagraph = () => {
        let currentContent = editor.getContent({ format: 'text' });

        setContent('<p>' + currentContent + '</p>');
        setHeadingType('');

        let tempJson = editorJson;

        let tempTagGroup = tagGroup;
        tempTagGroup.items[tempTagGroup.items.indexOf(rewriteNode)].rewrite.html = '<p>' + currentContent + '</p>';
        tempTagGroup.items[tempTagGroup.items.indexOf(rewriteNode)].rewrite.tagName = 'p';

        tempJson.source.tagGroups[tempTagGroup.id] = tempTagGroup;

        dispatch({
            type: actionTypes.SET_EDITOR_JSON,
            editorJson: tempJson
        });
    }

    const handleMoveUp = () => {
        let newIndex = rewriteNode.id - 1;

        let tempJson = editorJson;

        let tempTagGroup = tagGroup;

        if (tempTagGroup.items.length > 1) {
            if (rewriteNode.id > 0) {
                let tempItemStorage = tempTagGroup.items[newIndex];

                tempTagGroup.items[newIndex] = rewriteNode;
                tempTagGroup.items[newIndex].newItem = true;
                tempTagGroup.items[rewriteNode.id] = tempItemStorage;

                let counter = 0;
                tempTagGroup.items.map((item) => {
                    item.id = counter;
                    counter++;
                });

                tempJson.source.tagGroups[tempTagGroup.id] = tempTagGroup;
            } else {
                let guid = uuidv4();

                let newItemTagGroup = {
                    id: -1,
                    guid: guid,
                    tagName: tagGroup.tagName
                };

                let newItem = {
                    id: 0,
                    guid: uuidv4(),
                    parentGuid: guid,
                    tagName: rewriteNode.tagName,
                    html: rewriteNode.html,
                    newItem: true,
                    rewrite: {
                        html: rewriteNode.rewrite.html,
                        tagName: rewriteNode.rewrite.tagName
                    }
                };

                newItemTagGroup.items = new Array();
                newItemTagGroup.items.push(newItem);

                if (tagGroup.id > 0) {
                    tempJson.source.tagGroups.splice(tagGroup.id, 0, newItemTagGroup);
                } else {
                    tempJson.source.tagGroups.unshift(newItemTagGroup);
                }

                let counter = 0;
                tempJson.source.tagGroups.map((tagGroup) => {
                    tagGroup.id = counter;
                    counter++;
                });

                tempTagGroup.items.splice(rewriteNode.id, 1);

                counter = 0;
                tempTagGroup.items.map((item) => {
                    item.id = counter;
                    counter++;
                });

                tempJson.source.tagGroups[tempTagGroup.id] = tempTagGroup;
            }
        } else {
            let guid = uuidv4();
            let newTagGroup = tempJson.source.tagGroups[tempTagGroup.id - 1];

            if (!newTagGroup) {
                // console.log('creating new group');
                newTagGroup = {
                    id: -1,
                    guid: guid,
                    tagName: rewriteNode.rewrite.tagName
                };
            } else {
                // console.log('found group: ', newTagGroup);
            }

            let newItem = {
                id: 0,
                guid: uuidv4(),
                parentGuid: newTagGroup.guid,
                tagName: rewriteNode.tagName,
                html: rewriteNode.html,
                newItem: true,
                rewrite: {
                    html: rewriteNode.rewrite.html,
                    tagName: rewriteNode.rewrite.tagName
                }
            };

            if (!newTagGroup.items) {
                newTagGroup.items = new Array();
            }

            newTagGroup.items.push(newItem);

            let counter = 0;
            newTagGroup.items.map((item) => {
                item.id = counter;
                counter++;
            });

            if (tempTagGroup.items.length > 1) {
                tempTagGroup.items.splice(rewriteNode.id, 1);

                counter = 0;
                tempTagGroup.items.map((item) => {
                    item.id = counter;
                    counter++;
                });
            } else {
                tempJson.source.tagGroups.splice(tempTagGroup.id, 1);

                let counter = 0;
                tempJson.source.tagGroups.map((tagGroup) => {
                    tagGroup.id = counter;
                    counter++;
                });
            }
        }

        dispatch({
            type: actionTypes.SET_EDITOR_JSON,
            editorJson: tempJson
        });
    }

    const handleMoveDown = () => {
        let newIndex = rewriteNode.id + 1;

        let tempJson = editorJson;

        let tempTagGroup = tagGroup;

        // console.log('temp tag group items count: ', tempTagGroup.items.length, tempTagGroup);

        if (tempTagGroup.items.length > 1) {
            if (rewriteNode.id < tagGroup.items.length - 1) {
                let tempItemStorage = tempTagGroup.items[newIndex];

                tempTagGroup.items[newIndex] = rewriteNode;
                tempTagGroup.items[newIndex].newItem = true;
                tempTagGroup.items[rewriteNode.id] = tempItemStorage;

                let counter = 0;
                tempTagGroup.items.map((item) => {
                    item.id = counter;
                    counter++;
                });

                tempJson.source.tagGroups[tempTagGroup.id] = tempTagGroup;
            } else {
                let guid = uuidv4();

                let newItemTagGroup = {
                    id: -1,
                    guid: guid,
                    tagName: rewriteNode.rewrite.tagName
                };

                let newItem = {
                    id: 0,
                    guid: uuidv4(),
                    parentGuid: guid,
                    tagName: rewriteNode.tagName,
                    html: rewriteNode.html,
                    newItem: true,
                    rewrite: {
                        html: rewriteNode.rewrite.html,
                        tagName: rewriteNode.rewrite.tagName
                    }
                };

                newItemTagGroup.items = new Array();
                newItemTagGroup.items.push(newItem);

                tempJson.source.tagGroups.splice(tempTagGroup.id + 1, 0, newItemTagGroup);

                let counter = 0;
                tempJson.source.tagGroups.map((tagGroup) => {
                    tagGroup.id = counter;
                    counter++;
                });

                tempTagGroup.items.splice(rewriteNode.id, 1);

                counter = 0;
                tempTagGroup.items.map((item) => {
                    item.id = counter;
                    counter++;
                });

                tempJson.source.tagGroups[tempTagGroup.id] = tempTagGroup;
            }
        } else {
            let guid = uuidv4();
            let newTagGroup = tempJson.source.tagGroups[tempTagGroup.id + 1];

            if (!newTagGroup) {
                newTagGroup = {
                    id: -1,
                    guid: guid,
                    tagName: tagGroup.tagName
                };
            }

            let newItem = {
                id: 0,
                guid: uuidv4(),
                parentGuid: newTagGroup.guid,
                tagName: 'p',
                html: rewriteNode.html,
                newItem: true,
                rewrite: {
                    html: rewriteNode.rewrite.html,
                    tagName: rewriteNode.rewrite.tagName
                }
            };

            if (!newTagGroup.items) {
                newTagGroup.items = new Array();
            }

            newTagGroup.items.unshift(newItem);

            // console.log('new tag group: ', newTagGroup);

            let counter = 0;
            newTagGroup.items.map((item) => {
                item.id = counter;
                counter++;
            });

            if (tempTagGroup.items.length > 1) {
                tempTagGroup.items.splice(rewriteNode.id, 1);

                counter = 0;
                tempTagGroup.items.map((item) => {
                    item.id = counter;
                    counter++;
                });
            } else {
                tempJson.source.tagGroups.splice(tempTagGroup.id, 1);

                let counter = 0;
                tempJson.source.tagGroups.map((tagGroup) => {
                    tagGroup.id = counter;
                    counter++;
                });
            }
        }

        dispatch({
            type: actionTypes.SET_EDITOR_JSON,
            editorJson: tempJson
        });
    }

    const handleInsertParagraph = () => {
        let tempJson = editorJson;

        let newItem = {
            id: rewriteNode.id + 1,
            guid: uuidv4(),
            parentGuid: rewriteNode.parentGuid,
            tagName: 'p',
            html: '',
            rewrite: {
                html: '',
                tagName: 'p'
            }
        };

        let tempTagGroup = tagGroup;

        tempTagGroup.items.splice(tagGroup.items.indexOf(rewriteNode) + 1, 0, newItem);

        let counter = 0;
        tempTagGroup.items.map((item) => {
            item.id = counter;
            counter++;
        });

        tempJson.source.tagGroups[tempTagGroup.id] = tempTagGroup;

        dispatch({
            type: actionTypes.SET_EDITOR_JSON,
            editorJson: tempJson
        });
    }

    const handleNewParagraph = () => {
        let tempJson = editorJson;

        let parentGuid = uuidv4();
        let tempTagGroup = tagGroup;

        let newTagGroup = {
            id: -1,
            guid: parentGuid,
            tagName: 'p',
            items: [
                {
                    id: 0,
                    guid: uuidv4(),
                    tagName: 'h2',
                    html: '',
                    parentGuid: parentGuid,
                    rewrite: {
                        html: '<h2></h2>',
                        tagName: 'h2'
                    }
                },
                {
                    id: 1,
                    guid: uuidv4(),
                    tagName: 'p',
                    html: '',
                    parentGuid: parentGuid,
                    rewrite: {
                        html: '',
                        tagName: 'p'
                    }
                }
            ]
        };

        if (tempTagGroup.id < tempJson.source.tagGroups.length - 1) {
            tempJson.source.tagGroups.splice(tempTagGroup.id + 1, 0, newTagGroup);
        } else {
            tempJson.source.tagGroups.push(newTagGroup);
        }

        if (rewriteNode.id < tagGroup.items.length - 1) {
            let tempItems = new Array();
            let newParentGuid = uuidv4();

            let newItemsTagGroup = {
                id: -1,
                guid: newParentGuid,
                tagName: tagGroup.tagName
            };

            for (let i = rewriteNode.id + 1; i < tagGroup.items.length; i++) {
                tagGroup.items[i].guid = newParentGuid;
                tempItems.push(tagGroup.items[i]);
            }

            for (let i = tagGroup.items.length - 1; i > rewriteNode.id; i--) {
                tagGroup.items.splice(i, 1);
            }

            newItemsTagGroup.items = tempItems;

            if (tagGroupIndex < tempJson.source.tagGroups.length - 2) {
                tempJson.source.tagGroups.splice(tempTagGroup.id + 2, 0, newItemsTagGroup);
            } else {
                tempJson.source.tagGroups.push(newItemsTagGroup);
            }
        }

        let tagCounter = 0;
        tempJson.source.tagGroups.map((tagGroup) => {
            tagGroup.id = tagCounter;
            tagCounter++;
        });

        dispatch({
            type: actionTypes.SET_EDITOR_JSON,
            editorJson: tempJson
        });
    }

    const handleDelete = () => {
        let confirmation = window.confirm('Are you sure you want to delete this element?');

        if (!confirmation) return;

        let tempJson = editorJson;

        let tempTagGroup = tagGroup;

        if (tempTagGroup.items.length > 1) {
            tempTagGroup.items.splice(tempTagGroup.items.indexOf(rewriteNode), 1);

            let counter = 0;
            tempTagGroup.items.map((item) => {
                item.id = counter;
                counter++;
            });

            tempJson.source.tagGroups[tempTagGroup.id] = tempTagGroup;
        } else {
            tempJson.source.tagGroups.splice(tempTagGroup.id, 1);

            let counter = 0;
            tempJson.source.tagGroups.map((tagGroup) => {
                tagGroup.id = counter;
                counter++;
            });
        }

        dispatch({
            type: actionTypes.SET_EDITOR_JSON,
            editorJson: tempJson
        });
    }

    const handleSave = async () => {
        let tempData = {};
        tempData.Content = JSON.stringify(editorJson);
        tempData.Guid = match.params.rewriteId;

        const response = await SaveJson(tempData);
    }

    const handleThesaurusWordSelect = (word) => {
        editor.execCommand('mceInsertContent', true, word);
        //editor.dom.DomQuery.append(word);
    }

    return (
        <div ref={elementDivRef} className={`rewrite-element ${elementType} ${newItemClass}`}>
            <div className="rewrite-element-container">
                {showOriginal && (
                    <div className="original-text mb-3">
                        {originalSentence && originalSentence.length > 0 && (
                            <Fragment>
                                {originalSentence.map((wordDetails) => {
                                    return <WordComponent wordDetails={wordDetails} onWordSelected={handleThesaurusWordSelect} />
                                })}
                            </Fragment>
                        )}
                    </div>
                    // <div className="original-text mb-3" dangerouslySetInnerHTML={{ __html: rewriteNode.html }}>
                    // </div>
                )}

                {showRewrite && (
                    <div className="element-movement">
                        {tagGroup && (tagGroup.id > 0 || (tagGroup.id === 0 && tagGroup.items.length > 1)) && (
                            <button type="button" className="btn" tabIndex="-1" onClick={handleMoveUp}><i className="fas fa-chevron-up"></i></button>
                        )}

                        {tagGroup && (tagGroup.id < editorJson.source.tagGroups.length - 1 || (tagGroup.id === editorJson.source.tagGroups.length - 1 && tagGroup.items.length > 1)) && (
                            <button type="button" className="btn" tabIndex="-1" onClick={handleMoveDown}><i className="fas fa-chevron-down"></i></button>
                        )}
                    </div>
                )}

                {showRewrite && (
                    <Fragment>
                        {elementType === 'other' && (content.indexOf('<ul>') > -1 || content.indexOf('<ol>') > -1) && (
                            <span className="bullet-instructions">
                                Please re-order and re-write these bullet points.
                            </span>
                        )}
                    </Fragment>
                )}

                {showRewrite && (
                    <div className="element-editor-container">
                        <div className="element-editor">
                            <Editor
                                key="wi1lao33ed5u2op9p024nsxdc3yvuvb2qpmu5diz4jc1cxtu"
                                init={{
                                    valid_elements: "p[style],strong,em,span[style],a[href],ul,ol,li,h1,h2,h3,h4,h5,h6",
                                    menubar: false,
                                    statusbar: false,
                                    toolbar_location: 'bottom',
                                    toolbar: "formatselect | italic underline | numlist bullist | blockquote link | code",
                                    plugins: ['paste link lists code autolink'],
                                    paste_remove_styles: true,
                                    style_formats: [
                                        { title: 'Paragraph', inline: 'p' },
                                        { title: 'Heading', block: 'h2' }
                                    ]
                                }}
                                inline={true}
                                value={content}
                                onInit={handleEditorInit}
                                onFocus={handleEditorOnFocus}
                                onBlur={handleEditorOnBlur}
                                onEditorChange={handleEditorChange} />
                        </div>
                        <div className="element-actions">
                            {/* <div className="element-characters-count"><span>{wordsCount}</span> / {wordsLimit}</div> */}
                            {elementType !== '' && elementType !== 'other' && (
                                <Fragment>
                                    {elementType === 'paragraph' && (
                                        <button type="button" className="btn" onClick={handleChangeToHeadline} tabIndex="-1"><i className="fas fa-heading"></i></button>
                                    )}

                                    {elementType === 'other' && (
                                        <button type="button" className="btn" disabled={true} onClick={handleChangeToHeadline} tabIndex="-1"><i className="fas fa-heading"></i></button>
                                    )}

                                    {elementType === 'heading' && (
                                        <button type="button" className="btn" onClick={handleChangeToParagraph} tabIndex="-1"><i className="fas fa-font"></i></button>
                                    )}
                                </Fragment>
                            )}

                            <button type="button" className="btn" onClick={handleInsertParagraph} tabIndex="-1"><i className="fas fa-plus"></i></button>
                            <button type="button" className="btn" onClick={handleNewParagraph} tabIndex="-1"><i className="fas fa-paragraph"></i></button>
                            <button type="button" className="btn" onClick={handleDelete} tabIndex="-1"><i className="far fa-trash-alt"></i></button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default RewriteElement;
