import { Editor } from '@tinymce/tinymce-react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { negativeWords } from '../../negativeWords';
import { actionTypes } from '../../reducer';
import { GetJson, GetNegativeTermsList, SaveJson } from '../../Services';
import { useStateValue } from '../../StateProvider';
import Header from '../header/Header';
import ModalDialog from '../modalDialog/ModalDialog';
import ModalDialogButtons from '../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../modalDialog/modalDialogContent/ModalDialogContent';
import RewriteElementsGroup from '../rewriteElementsGroup/RewriteElementsGroup';
import SourceContent from '../sourceContent/SourceContent';
import TimerComponent from '../timerComponent/TimerComponent';
import './RewriteLoader.css';

const RewriteLoader = () => {
    const [{ editorJson, wordsLimit, writingTime }, dispatch] = useStateValue();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [articleLink, setArticleLink] = useState('');
    const [clipboardMessage, setClipboardMessage] = useState('');
    const clipboardRef = useRef(null);
    const [showOriginal, setShowOriginal] = useState(true);
    const [showRewrite, setShowRewrite] = useState(true);
    const [content, setContent] = useState('');
    const [editor, setEditor] = useState(null);
    const [wordsCount, setWordsCount] = useState(0);
    const [showOriginalArticle, setShowOriginalArticle] = useState(false);
    const [showAgreeTerms, setShowAgreeTerms] = useState(true);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [showMustAgreeToTerms, setShowMustAgreeToTerms] = useState(false);
    const history = useHistory();
    const match = useRouteMatch();

    useEffect(() => {
        if (termsAgreed) {
            loadJson();
            getNegativeTerms();
        } else {
            if (!showAgreeTerms) {
                setShowMustAgreeToTerms(true);
            }
        }
    }, [termsAgreed, showAgreeTerms]);

    const loadJson = async () => {
        let tempData = {};
        tempData.Guid = match.params.rewriteId;

        const response = await GetJson(tempData);

        if (response.IsSuccessful) {
            let tempJson = JSON.parse(response.Data);
            let tempArray = new Array();

            if (tempJson.WordsLimit) {
                let wordsLimit = parseInt(tempJson.WordsLimit);

                dispatch({
                    type: actionTypes.SET_WORDS_LIMIT,
                    wordsLimit: wordsLimit
                });
            }

            let tagCounter = 0;
            tempJson.source.tagGroups.map((tagGroup) => {
                tagGroup.guid = uuidv4();
                tagGroup.id = tagCounter;
                if (tagGroup.items && tagGroup.items.length > 0) {
                    let itemCounter = 0;
                    tagGroup.items.map((item) => {
                        item.id = itemCounter;
                        item.parentGuid = tagGroup.guid;
                        item.guid = uuidv4();

                        if (!item.rewrite) {
                            item.rewrite = {
                                tagName: item.tagName,
                                html: ''
                            };

                            if (item.html.indexOf('<ul>') > -1 || item.html.indexOf('<ol>') > -1) {
                                item.rewrite.html = item.html;
                            }
                        } else {
                            if (item.rewrite.html === '' && (item.html.indexOf('<ul>') > -1 || item.html.indexOf('<ol>') > -1)) {
                                item.rewrite.html = item.html;
                            }
                        }

                        itemCounter++;
                    });
                }

                tagCounter++;
            });

            dispatch({
                type: actionTypes.SET_EDITOR_JSON,
                editorJson: tempJson
            });

            setArticleLink(window.location.origin + '/' + match.params.rewriteId);

            setTimeout(() => {
                setDataLoaded(true);
            }, 100);
        } else {
            window.alert('Content not found!');
        }
    }

    const getNegativeTerms = async () => {
        const response = await GetNegativeTermsList();
        let termsData = null;

        if (response.IsSuccessful) {
            termsData = JSON.parse(response.Data);

            dispatch({
                type: actionTypes.SET_NEGATIVE_TERMS,
                negativeTerms: termsData
            });
        }
    }

    useEffect(() => {
        calculateWordsCount();
    }, [editorJson]);

    const handleEditorInit = (evt, editor) => {
        setEditor(editor);

        if (editorJson) {
            calculateWordsCount();
        }
    }

    const calculateWordsCount = () => {
        if (editorJson) {
            console.log('calculating words count');
            let finishedContent = '';
            editorJson.source.tagGroups.map((tagGroup) => {
                if (tagGroup.items && tagGroup.items.length > 0) {
                    if (tagGroup.tagName === 'p') {
                        finishedContent += '<p>';
                    }

                    tagGroup.items.map((item) => {
                        if (item.rewrite && item.rewrite.tagName !== 'p' && tagGroup.tagName === 'p' && item.rewrite.html.trim() !== '' && item.rewrite.html.trim() !== '<br>') {
                            finishedContent += '</p>';
                        }

                        let previousItem = tagGroup.items[tagGroup.items.indexOf(item) - 1];

                        if (previousItem && previousItem.tagName !== item.tagName && item.tagName === 'p') {
                            finishedContent += '<p>';
                        }

                        if (item.rewrite && item.rewrite.html.trim() !== '' && item.rewrite.html.trim() != '<br>' && item.rewrite.html.trim() != '<br/>' && item.rewrite.html.trim() != '<br />') {
                            if (item.rewrite.tagName === 'p') {
                                finishedContent += `${item.rewrite.html.replace('<p>', '').replace('</p>', '')} `;
                            } else {
                                finishedContent += `${item.rewrite.html}`;
                            }
                            //finishedContent += `<${item.rewrite.tagName}>${item.rewrite.html}</${item.rewrite.tagName}>`;
                        }

                        if (item == tagGroup.items[tagGroup.items.length - 1] && finishedContent.endsWith(' ')) {
                            finishedContent = finishedContent.substr(0, finishedContent.length - 1);
                        }
                    });

                    if (tagGroup.tagName === 'p' && !finishedContent.endsWith('</p>')) {
                        finishedContent += '</p>';
                    }
                }
            });

            while (finishedContent.indexOf('<p></p>') > -1) {
                finishedContent = finishedContent.replace('<p></p>', '');
            }

            setContent(finishedContent);

            console.log('finished calculating words count');
        }
    }

    const handleEditorChange = (newContent, editor) => {
        setContent(newContent);
    }

    useEffect(() => {
        if (editor) {
            //let textContent = editor.getContent({ format: 'text' });

            //let words = textContent.split(' ');
            const wordcount = editor.plugins.wordcount;

            // setWordsCount(words.length);
            setWordsCount(wordcount.body.getWordCount());
        } else {
            setWordsCount(0);
        }
    }, [editor, content]);

    useEffect(() => {
        setClipboardMessage(`Please write a 100% unique article using the SteadyContent rewrite utility: ${articleLink}`);
    }, [articleLink]);

    const handleNextButton = async () => {
        await handleSave();
        history.push('/' + match.params.rewriteId + '/complete');
    }

    const handleCopyButton = () => {
        clipboardRef.current.style.visibility = 'visible';

        clipboardRef.current.select();
        document.execCommand('copy');

        clipboardRef.current.style.visibility = 'hidden';
    }

    const handleShowOriginal = (e) => {
        if (e.target.checked) {
            setShowOriginal(true);
        } else {
            setShowOriginal(false);
        }
    }

    const handleShowRewrite = (e) => {
        if (e.target.checked) {
            setShowRewrite(true);
        } else {
            setShowRewrite(false);
        }
    }

    const handleShowOriginalArticle = () => {
        setShowOriginalArticle(!showOriginalArticle);
    }

    const handleSave = async () => {
        let tempData = {};

        console.log('writing time: ', writingTime);

        let tempEditorJson = editorJson;
        tempEditorJson.totalTime = writingTime;

        dispatch({
            type: actionTypes.SET_EDITOR_JSON,
            editorJson: tempEditorJson
        });

        tempData.Content = JSON.stringify(tempEditorJson);
        tempData.Guid = match.params.rewriteId;

        const response = await SaveJson(tempData);

        if (response.IsSuccessful) {
            toast.success('Content saved successfully!');
        }
    }

    const toggleTermsDialog = () => {
        setShowAgreeTerms(prev => !prev);
    }

    const handleAcceptTerms = () => {
        setTermsAgreed(true);
        toggleTermsDialog();
    }

    const handleCancelTerms = () => {
        toggleTermsDialog();
    }

    return (
        <Fragment>
            <Header />
            {dataLoaded && negativeWords && editorJson && editorJson.source && editorJson.source.tagGroups && editorJson.source.tagGroups.length > 0 && (
                <SourceContent sourceContent={editorJson.source} show={showOriginalArticle} />
            )}

            <div className="container rewrite-container">
                {dataLoaded && negativeWords && editorJson && editorJson.source && editorJson.source.tagGroups && editorJson.source.tagGroups.length > 0 && (
                    <Fragment>

                        <h3>Let's rewrite some content!</h3>

                        <div className="rewrite-instructions">
                            <p>Rewrite each sentence into a 100% unique sentence. Use the buttons to convert to an H2 title tag, add
                                a sentence, create a paragraph or delete a sentence.
                            </p>

                            <div className="rewrite-instructions-video">
                                <span className="wistia_embed wistia_async_0ilmh21rbu popover=true popoverContent=link">
                                    <a href="#" className="link-training"><i class="fab fa-youtube"></i><span>Watch Training Video!</span></a>
                                </span>
                            </div>
                        </div>

                        {editorJson.source.tagGroups.map((tagGroup) => (
                            <RewriteElementsGroup key={tagGroup.guid} tagGroup={tagGroup} items={tagGroup.items} showOriginal={showOriginal} showRewrite={showRewrite} calculateWordsCount={calculateWordsCount} wordsCount={wordsCount} />
                        ))}

                        <textarea ref={clipboardRef} id="txtClipboard" className="clipboard" defaultValue={clipboardMessage}>
                        </textarea>

                        <div className="word-counter-editor">
                            <Editor
                                key="wi1lao33ed5u2op9p024nsxdc3yvuvb2qpmu5diz4jc1cxtu"
                                init={{
                                    valid_elements: "p[style],strong,em,span[style],a[href],ul,ol,li,h1,h2,h3,h4,h5,h6",
                                    menubar: false,
                                    statusbar: false,
                                    toolbar: "formatselect | italic underline | numlist bullist | blockquote link | code",
                                    plugins: ['wordcount link lists code autolink'],
                                    style_formats: [
                                        { title: 'Paragraph', inline: 'p' },
                                        { title: 'Heading', block: 'h2' }
                                    ]
                                }}
                                inline={false}
                                initialValue={content}
                                value={content}
                                onChange={handleEditorChange}
                                onInit={handleEditorInit} />
                        </div>

                        <div className="article-actions-container">
                            <button type="button" className="btn btn-primary" onClick={handleShowOriginalArticle}>
                                {!showOriginalArticle ? (
                                    <span>Show Source Content</span>
                                ) : (
                                    <span>Hide Source Content</span>
                                )}
                            </button>

                            <div className="text-switcher">
                                <div className="switch-original-text">
                                    <input type="checkbox" className="sc-checkbox-switch" id="chkSwitchOriginal" defaultChecked={showOriginal} onChange={handleShowOriginal} /><label htmlFor="chkSwitchOriginal">Original</label>
                                </div>
                                <div className="switch-rewritten-text">
                                    <input type="checkbox" className="sc-checkbox-switch" id="chkSwitchRewritten" defaultChecked={showRewrite} onChange={handleShowRewrite} /><label htmlFor="chkSwitchRewritten">Rewrite</label>
                                </div>
                            </div>

                            <div className="article-link">
                                <span>{articleLink}</span>
                                <button type="button" className="btn btn-primary" onClick={handleCopyButton}>Copy</button>
                                <button type="button" className="btn btn-primary" onClick={handleSave}>Save</button>
                            </div>

                            <div className="words-counter">
                                <span>Words: </span>
                                {wordsCount < wordsLimit ? (
                                    <span>
                                        <span className="red">{wordsCount}</span> / {wordsLimit}
                                    </span>
                                ) : (
                                    <span>
                                        <span className="green">{wordsCount}</span> / {wordsLimit}
                                    </span>
                                )}
                            </div>

                            <TimerComponent />

                            <button type="button" className="btn btn-primary" onClick={handleNextButton}><span>Next</span></button>
                        </div>
                    </Fragment>
                )}
            </div>
            <div className="rewriter-page-offseter"></div>

            {showAgreeTerms && (
                <Fragment>
                    <ModalDialog>
                        <ModalDialogContent>
                            <h5 className="mb-3">Terms and Conditions</h5>
                            <p><strong>AGREEMENT:</strong> By using this site, you agree that this tool's purpose is to help you writer FASTER. Please keep in mind that the article MUST follow our guidelines and instructions, and the article's quality mustn't suffer. Please use your best judgment on whether or not to use this tool to write your article.</p>
                            <p>Remember that your article must be 100% uniquely your own wording and put in context for your client. Just because something appears in the rewriter article does not mean you must put it in your piece. Make everything you write relevant to what your client does or offers.</p>
                        </ModalDialogContent>
                        <ModalDialogButtons align="right">
                            <button type="button" className="btn btn-outline-secondary" onClick={handleCancelTerms}>
                                <span>Cancel</span>
                            </button>
                            <button type="button" className="btn btn-primary" onClick={handleAcceptTerms}>
                                <span>Accept</span>
                            </button>
                        </ModalDialogButtons>
                    </ModalDialog>
                </Fragment>
            )}

            {showMustAgreeToTerms && (
                <Fragment>
                    <ModalDialog>
                        <ModalDialogContent>
                            <h5 className="mb-3">Terms and Conditions</h5>
                            <p>It is required to agree to the terms and conditions of this tool to be able to use it to write content.</p>
                        </ModalDialogContent>
                    </ModalDialog>
                </Fragment>
            )}
        </Fragment>
    )
}

export default RewriteLoader;
