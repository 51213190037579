import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { Fragment } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import RewriteLoader from './components/rewriteLoader/RewriteLoader';
import RewriteTest from './components/rewriteTest/RewriteTest';
import ContentParser from './components/contentParser/ContentParser';
import ContentComplete from './components/contentComplete/ContentComplete';
import { ToastContainer } from 'react-toastify';
import NegativeListManagement from './components/negativeListManagement/NegativeListManagement';

function App() {
  return (
    <Fragment>
      <Router>
        <Switch>
          <Route path="/negative-terms">
            <NegativeListManagement />
          </Route>
          <Route path="/complete">
            <ContentComplete />
          </Route>
          <Route path="/:rewriteId/complete" exact={true}>
            <ContentComplete />
          </Route>
          <Route path="/:rewriteId" exact={true}>
            <RewriteLoader />
          </Route>
          <Route path="/">
            <ContentParser />
          </Route>
        </Switch>
      </Router>

      <ToastContainer
        position='bottom-left'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
      />
    </Fragment>
  );
}

export default App;
