import { useEffect, useState } from 'react';
import './SourceContent.css';

const SourceContent = ({ sourceContent, show }) => {
    const [activeClass, setActiveClass] = useState('');

    useEffect(() => {
        if (show) {
            setActiveClass('active');
        } else {
            setActiveClass('');
        }
    }, [show]);

    return (
        <div className={`source-content-container ${activeClass}`}>
            <div className="source-content" dangerouslySetInnerHTML={{ __html: sourceContent.html }}></div>
        </div>
    )
}

export default SourceContent;
