import { Editor } from '@tinymce/tinymce-react';
import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { actionTypes } from '../../reducer';
import { CreateJson } from '../../Services';
import { useStateValue } from '../../StateProvider';
import Header from '../header/Header';
import './ContentParser.css';

const ContentParser = () => {
    const history = useHistory();
    const [{ editorJson }, dispatch] = useStateValue();
    const [editor, setEditor] = useState(null);
    const [content, setContent] = useState('');
    const [wordsLimitCount, setWordsLimitCount] = useState(1750);
    const sentenceMatch = /([^\.!\?]+[\.!\?]+)|([^\.!\?]+$)/g;

    const handleEditorInit = (evt, editor) => {
        setEditor(editor);
    }

    const handleEditorChange = (newContent, editor) => {
        setContent(newContent);
    }

    const handleNextButton = async () => {
        let jsonObject = { "source": {}, "rewrite": {} };

        jsonObject.source.html = editor.getContent();
        jsonObject.source.tagGroups = [];
        jsonObject.rewrite.html = "";
        jsonObject.rewrite.tagGroups = [];

        // if (jsonObject.source.html.indexOf('<p>') < 0) {
        //     jsonObject.source.html = '<p>' + jsonObject.source.html.replace('<br>', '</p><p>') + '</p>';
        // }

        let doc = new DOMParser().parseFromString(jsonObject.source.html, "text/html");

        if (doc != null && doc.all.length > 0) {
            jsonObject = loopChildNodes(doc, jsonObject);

            dispatch({
                type: actionTypes.SET_EDITOR_JSON,
                editorJson: jsonObject
            });

            dispatch({
                type: actionTypes.SET_WORDS_LIMIT,
                wordsLimit: wordsLimitCount
            });

            jsonObject.WordsLimit = wordsLimitCount;

            let tempJsonData = {};
            tempJsonData.Content = JSON.stringify(jsonObject);

            const response = await CreateJson(tempJsonData);

            if (response.IsSuccessful) {
                history.push('/' + response.Data);
            } else {
                window.alert('Unknown error occurred!');
                return;
            }
        }
    }

    const loopChildNodes = (node, jsonObject) => {
        if (node.all !== undefined && node.all !== null && node.all.length > 0) {
            let allowedTags = new Array('p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'br');

            let orderId = 0;

            for (let i = 0; i < node.all.length; i++) {
                let currentTag = node.all[i];

                if (!allowedTags.includes(currentTag.nodeName.toLowerCase())) {
                    continue;
                }

                let tagGroup = {};

                console.log(currentTag, currentTag.nodeName);

                if (currentTag.nodeName.toLowerCase() == "p") {
                    //split paragraph into sentences
                    //let itemsArr = currentTag.innerHTML.replace(/(\.+|\:|\!|\?)(\"*|\'*|\)*|}*|]*)(\s|\n|\r|\r\n)/gm, "$1$2|").split("|");
                    //let itemsArr = currentTag.innerText.split('.');
                    let itemsArr = currentTag.innerText.match(sentenceMatch);

                    if (itemsArr !== null && itemsArr.length) {
                        let items = [];
                        for (let j = 0; j < itemsArr.length; j++) {
                            //let item = { "id": j, "tagName": currentTag.nodeName.toLowerCase(), "html": itemsArr[j] + '.', "rewriteHtml": "" };
                            if (itemsArr[j].trim() !== '') {
                                let item = { "id": j, "tagName": 'p', "html": '<p>' + itemsArr[j].trim() + '</p>', "rewriteHtml": "" };
                                items.push(item);
                            }
                        }

                        tagGroup = { "id": orderId, "tagName": currentTag.nodeName.toLowerCase(), "items": items };
                    }
                }
                else {
                    let nodeName = currentTag.nodeName.toLowerCase();
                    let items = [{ "id": orderId, "tagName": currentTag.nodeName.toLowerCase(), "html": '<' + nodeName + '>' + currentTag.innerHTML + '</' + nodeName + '>', "rewriteHtml": "" }];

                    tagGroup = { "id": orderId, "tagName": currentTag.nodeName.toLowerCase(), "items": items };
                }

                if (tagGroup.items && tagGroup.items.length > 0) {
                    jsonObject.source.tagGroups.push(tagGroup);
                }

                orderId++;
            }
        }

        return jsonObject;
    }

    const handleWordsLimitChange = (e) => {
        setWordsLimitCount(e.target.value);
    }

    return (
        <Fragment>
            <Header />
            <div className="container parser-container">
                <h3 className="mb-3">Welcome to the SteadyContent fast write utility.</h3>

                <p>How many words should the FINISHED article be?</p>
                <input type="number" className="form-control mb-3 word-count" value={wordsLimitCount} onChange={handleWordsLimitChange} />
                <div className="instructions-container">
                    <p>Paste the content to be rewritten below and click Next.</p>
                    <span className="wistia_embed wistia_async_0ilmh21rbu popover=true popoverContent=link">
                        <a href="javascript:void(0)"><i class="fab fa-youtube"></i><span>Watch Training Video</span></a>
                    </span>
                </div>
                <Editor
                    key="wi1lao33ed5u2op9p024nsxdc3yvuvb2qpmu5diz4jc1cxtu"
                    init={{
                        valid_elements: "p[style],strong,em,span[style],a[href],ul,ol,li,h1,h2,h3,h4,h5,h6",
                        menubar: false,
                        statusbar: true,
                        toolbar: "formatselect | italic underline | numlist bullist | blockquote link | code",
                        plugins: ['wordcount paste link lists code autolink'],
                        paste_remove_styles: true,
                        style_formats: [
                            { title: 'Paragraph', inline: 'p' },
                            { title: 'Heading', block: 'h2' }
                        ]
                    }}
                    inline={false}
                    initialValue={content}
                    onInit={handleEditorInit}
                    onEditorChange={handleEditorChange} />
                <div className="actions">
                    <button type="button" className="btn btn-primary" onClick={handleNextButton}><span>Next</span></button>
                </div>
            </div>
        </Fragment>
    )
}

export default ContentParser;
