import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import WordSuggestion from '../wordComponent/WordSuggestion';
import './ThesaurusDialog.css';

const ThesaurusDialog = ({ word, onWordSelected }) => {
    const apiUrl = window.location.origin + '/Services/Service.asmx/ThesaurusQuery';
    const [isLoading, setIsLoading] = useState(true);
    const [thesaurusData, setThesaurusData] = useState(null);

    useEffect(() => {
        getThesaurusData();
    }, []);

    const getThesaurusData = async () => {
        let data = {};
        data.Word = word;
        const response = await axios.post(apiUrl, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => response.data.d)
            .then((data) => {
                console.log(data);

                return data;
            });

        if (response.IsSuccessful) {
            setThesaurusData(JSON.parse(response.Data));
        }

        setIsLoading(false);
    }

    const handleWordSelected = (selectedWord) => {
        console.log(selectedWord);
        onWordSelected(selectedWord);
    }

    return (
        <div className="thesaurus-dialog">
            {!isLoading ? (
                <Fragment>
                    {thesaurusData ? (
                        <Fragment>
                            {thesaurusData.noun && thesaurusData.noun.syn && (
                                <Fragment>
                                    <h4>n.</h4>

                                    <div className="suggestions-container">
                                        {thesaurusData.noun.syn.map((syn) => (
                                            <WordSuggestion word={syn} onWordSelected={handleWordSelected} />
                                        ))}
                                    </div>
                                </Fragment>
                            )}

                            {thesaurusData.verb && thesaurusData.verb.syn && (
                                <Fragment>
                                    <h4>v.</h4>

                                    <div className="suggestions-container">
                                        {thesaurusData.verb.syn.map((syn) => (
                                            <WordSuggestion word={syn} onWordSelected={handleWordSelected} />
                                        ))}
                                    </div>
                                </Fragment>
                            )}

                            {thesaurusData.adjective && thesaurusData.adjective.syn && (
                                <Fragment>
                                    <h4>adj.</h4>

                                    <div className="suggestions-container">
                                        {thesaurusData.adjective.syn.map((syn) => (
                                            <WordSuggestion word={syn} onWordSelected={handleWordSelected} />
                                        ))}
                                    </div>
                                </Fragment>
                            )}

                            {thesaurusData.adverb && thesaurusData.adverb.syn && (
                                <Fragment>
                                    <h4>adv.</h4>

                                    <div className="suggestions-container">
                                        {thesaurusData.adverb.syn.map((syn) => (
                                            <WordSuggestion word={syn} onWordSelected={handleWordSelected} />
                                        ))}
                                    </div>
                                </Fragment>
                            )}

                            {Array.isArray(thesaurusData) && (
                                <Fragment>
                                    <h4>syn.</h4>

                                    <div className="suggestions-container">
                                        {thesaurusData.map((syn) => (
                                            <WordSuggestion word={syn} onWordSelected={handleWordSelected} />
                                        ))}
                                    </div>
                                </Fragment>
                            )}
                        </Fragment>
                    ) : (
                            <div className="alert alert-warning text-center m-0">
                                <span>No data could be found for this word.</span>
                            </div>
                        )}
                </Fragment>
            ) : (
                    <Fragment>
                        <div className="text-center">
                            <div class="spinner-border" role="status"></div>
                        </div>
                    </Fragment>
                )}
        </div>
    )
}

export default ThesaurusDialog;
