import './Header.css';

const Header = () => {
    return (
        <div className="container-fluid header">
            <div className="container">
                <div className="logo">
                    <img src="https://www.steadycontent.com/wp-content/uploads/2018/09/SC-Logo-AllC.png" alt="Logo" />
                </div>
            </div>
        </div>
    )
}

export default Header;
