import { Fragment } from 'react';
import './ModalDialog.css';

const ModalDialog = ({ children }) => {
	return (
		<Fragment>
			<div className="modal-background" />
			<div className="modal-dialog-container">
				<div className="modal-dialog shadow-large">
					{children}
				</div>
			</div>
		</Fragment>
	);
};

export default ModalDialog;
